<template>
  <div class="car-track">
    <x-table
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
    ></x-table>

    <pic-view v-model="checkPic" :src="imgSrc"></pic-view>

    <track-model v-model="trackModelShow"></track-model>
  </div>
</template>

<script>
import picView from "@/components/picture_view";
import trackModel from "./trackModel.vue"
export default {
  name: "",
  components: {
    picView,
    trackModel
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: "图片",
            minWidth: 100,
            render: (h, { row }) => {
              return (
                <div
                  style="width:60px;height:60px;padding:4px;cursor:pointer"
                  on-click={() => this.checkPicInfo(row)}
                >
                  <img
                    style="width:100%;height:100%"
                    src={this.getImgUrl(row.servicePath, row.carImgUrl)}
                  />
                </div>
              );
            },
          },
          {
            title: "车牌号",
            minWidth: 100,
            key: "carPlate",
          },
          {
            title: "车辆类型",
            minWidth: 100,
            key: "carType",
          },
          {
            title: "车辆颜色",
            minWidth: 100,
            key: "carColor",
          },
          {
            title: "车辆品牌",
            minWidth: 100,
            key: "carModel",
          },
          {
            title: "司机",
            minWidth: 100,
            render: (h, { row }) => {
              return <span>{row.transportList[0].driverName}</span>
            }
          },
          {
            title: "司机电话",
            minWidth: 130,
            render: (h, { row }) => {
              return <span>{row.transportList[0].driverPhone}</span>
            }
          },
          {
            title: '起始地址',
            minWidth: 100,
            render: (h, { row }) => {
              return <span>{row.transportList[0].originAddress}</span>
            }
          },
          {
            title: '目的地',
            minWidth: 100,
            render: (h, { row }) => {
              return <span>{row.transportList[0].transportAddress}</span>
            }
          },
          {
            title: '收货人',
            minWidth: 100,
            render: (h, { row }) => {
              return <span>{row.transportList[0].receiveName}</span>
            }
          },
          {
            title: '收货人电话',
            minWidth: 100,
            render: (h, { row }) => {
              return <span>{row.transportList[0].receivePhone}</span>
            }
          },
          {
            title: "所属公司",
            minWidth: 100,
            key: "carCompanyName",
          },
          {
            title: '操作',
            width: 100,
            render: (h, { row }) => {
              return (
                <div>
                  <a onClick={() => this.track(row)}>车辆跟踪</a>
                </div>
              )
            }
          }
        ],
        data: [],
        loading: false,
      },
      search_data: {},
      //查看车辆图片
      checkPic: false,
      //车辆图片地址
      imgSrc: "",
      trackModelShow: false
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          width: 200,
          filterBox: [
            {
              conditionName: "车牌号",
              component: "input",
              field: "carPlate",
              defaultValue: "",
            },
          ],
        },
      };
      return config;
    },
  },
  methods: {
    search(data) {
      this.page.pageNo = 1;
      this.search_data = data;
      this.getList();
    },

    getList() {
      this.table.loading = true;
      this.$post(this.$api.TRANSPORT.CAR_STATUS, {
        ...this.search_data,
      })
        .then((res) => {
          console.log(res);
          this.table.data = res.filter(item => item.transportList.length > 0);
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    //查看图片
    checkPicInfo(row) {
      this.checkPic = true;
      this.imgSrc = row.servicePath + row.carImgUrl;
    },
    track(row) {
      this.trackModelShow = true
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.car-track {
  width: 100%;
  height: 100%;
}
</style>